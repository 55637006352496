/**
 * @deprecated used loading="lazy" instead
 */
window.addEventListener('DOMContentLoaded', () => {
  // IntersectionObserver isn't available, we can't use lazyloading on images
  if (!('IntersectionObserver' in window)) {
    const imagesToLazyLoad = document.querySelectorAll('img.lzy_img')
    imagesToLazyLoad.forEach((v) => {
      v.src = v.dataset.src
    })
  }
  // Lazy loading of pictures
  else {
    const imageObserver = new IntersectionObserver((entries, imgObserver) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const lazyImage = entry.target
          lazyImage.src = lazyImage.dataset.src
          lazyImage.classList.remove('lzy_img')
          imgObserver.unobserve(lazyImage)
        }
      })
    })
    const imagesToLazyLoad = document.querySelectorAll('img.lzy_img')
    imagesToLazyLoad.forEach((v) => {
      imageObserver.observe(v)
    })
  }
})
