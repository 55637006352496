window.addEventListener('DOMContentLoaded', () => {
  const dropdownNotifications = document.querySelector('.dropdown .dropdown__notifications')

  if (!dropdownNotifications) return

  const titles = dropdownNotifications.querySelectorAll('.titles > h3')
  const tabs = dropdownNotifications.querySelectorAll('ul[data-tab]')

  if (titles.length > 0)
    titles.forEach((title) => {
      title.addEventListener('click', () => {
        titles.forEach((tl) => tl.classList.remove('active'))

        title.classList.add('active')

        tabs.forEach((tab) => {
          if (tab.dataset.tab === title.dataset.id) {
            tab.classList.add('active')
          } else {
            tab.classList.remove('active')
          }
        })
      })
    })
})
