import Cookies from 'js-cookie'
import initDropdowns from './block/layout/dropdown'
import { containsQueryParam } from './utils/url'
import updateUserDevice from './utils/user-device'

import Carousel from './block/carousel'
import noImageProduct from '../images/no-photo.png'

import 'intersection-observer'
import './polyfill'
import './component/async_elements_react'
import './component/with_link'
import './component/prevent_default'
import './component/wishlist'
import './component/product_card'
import './component/inputs/checkbox'
import './component/accordion_filter'
import './component/filters_panel'
import './component/inputs/input'
import './component/collapse_block'
import './block/modal'
import './block/modals/make_offer'
import './block/modals/transfer_status'
import './block/modals/promo_code'
import './block/modals/calendly'
import './block/aside-modal'
import './block/aside-modal/aside-modal-user-reviews'
import './block/product/contactSeller'
import './block/main-header/menu-dropdown'
import './block/announces'
import './block/faq'
import './utils/form'
import './utils/lazy-image'
import './helpers/border'
import './block/banner'
import './block/saved-search/saved-search'
import './block/products/parts/purchase-protection'

function ImageExist(url) {
  const img = new Image()
  img.src = url
  return img.height !== 0
}
window.addEventListener('DOMContentLoaded', () => {
  initDropdowns(document)

  // Parse user's device.
  if (!Cookies.get('userParsedDevice')) updateUserDevice()

  // Check if images load
  const imagesToCheck = document.querySelectorAll('img')
  imagesToCheck.forEach((i) => {
    i.addEventListener('error', () => {
      // If the image Should be removed on error
      if (i.hasAttribute('data-remove-on-error')) {
        i.remove()
      }
      // If the image doesn't load replace it with a placeholder
      if (!ImageExist(i.src)) {
        i.src = noImageProduct
        i.dataset.src = noImageProduct
      }
    })
  })

  // Notifications
  const notificationDeleteButtons = document.querySelectorAll('#notifications .notification .delete')

  if (notificationDeleteButtons && notificationDeleteButtons.length > 0)
    notificationDeleteButtons.forEach((deleteButton) => {
      deleteButton.addEventListener('mouseup', () => {
        deleteButton.parentNode.style.animationDelay = '0s'
        deleteButton.parentNode.classList += ' remove'
      })
    })

  const mainHeaderCart = document.getElementById('main-header-cart')
  if (mainHeaderCart) {
    mainHeaderCart.addEventListener('click', () => {
      window.showElementsAsync('react-modals-container', 'aside-cart-modal', 'Cart/CartModal/CartModal')
    })
    window.addEventListener('addedCartEntry', () => {
      mainHeaderCart.dataset.count = '1'
      mainHeaderCart.classList.add('main-header-ctas__icon--notice')
      mainHeaderCart.classList.remove('main-header-ctas__icon--hidden-notice')
    })
    window.addEventListener('removedCartEntry', () => {
      mainHeaderCart.dataset.count = '0'
      mainHeaderCart.classList.remove('main-header-ctas__icon--notice')
    })
  }

  if (containsQueryParam('panier')) {
    window.showElementsAsync('react-modals-container', 'aside-cart-modal', 'Cart/CartModal/CartModal')
  }

  const searchbox = document.getElementById('main-header-searchbox-trigger')
  if (searchbox) {
    const { featuredItems, suggestedItems } = searchbox.dataset

    window.showElementsAsync(
      'main-header-searchbox-trigger',
      'main-header-searchbox-trigger-cpt',
      'Search/SearchBoxTrigger',
      {
        featuredItems: featuredItems ? JSON.parse(featuredItems) : [],
        suggestedItems: suggestedItems ? JSON.parse(suggestedItems) : [],
      },
      { ignoreToaster: true }
    )
  }

  if (document.getElementById('sell-fast-carousel')) {
    const SellFastCarousel = () =>
      new Carousel(document.getElementById('sell-fast-carousel'), {
        arrow: false,
        dots: true,
        overflow: false,
      })
    SellFastCarousel()
  }

  if (document.getElementById('how-to-buy-carousel')) {
    const HowToBuyCarousel = () =>
      new Carousel(document.getElementById('how-to-buy-carousel'), {
        arrow: false,
        dots: true,
        overflow: false,
      })
    HowToBuyCarousel()
  }

  // Prevent interaction on disabled element
  document.body.addEventListener('click', (e) => {
    if (e.target.classList.contains('is-disabled')) {
      e.stopPropagation()
      e.preventDefault()
    }
  })
})
