import Swiper, { Navigation, Pagination } from 'swiper'
import { isMobile } from '../utils/responsive'

const addPaginationToSwiper = (swiper) => {
  swiper.insertAdjacentHTML(
    'beforeend',
    '<div class="product-card__navigation product-card__navigation--prev" data-prevent-navigation="true"></div>'
  )
  swiper.insertAdjacentHTML(
    'beforeend',
    '<div class="product-card__navigation product-card__navigation--next" data-prevent-navigation="true"></div>'
  )
}

const addMissingSlideToSwiper = (swiper, swiperPictures, hasLargeLayout = false) => {
  const swiperWrapper = swiper.querySelector('.swiper-wrapper')
  if (!swiperWrapper) return

  swiperWrapper.insertAdjacentHTML(
    'beforeend',
    swiperPictures
      .slice(1)
      .map(
        (pictureUri) => `
          <div class="product-card__picture swiper-slide">
            <figure class="product-card__figure image ${hasLargeLayout ? '' : 'is-1by1'}">
              <img
                alt=""
                class="product-card__image ${hasLargeLayout ? 'product-card__image--large' : ''}"
                src="${pictureUri}"
                width="390"
                height="390"
                loading="lazy"
              />
            </figure>
          </div>
          `
      )
      .join('')
  )
}

const initializeSwiper = (swiper) => {
  // eslint-disable-next-line no-new
  new Swiper(swiper, {
    slidesPerView: 'auto',
    modules: [Navigation, Pagination],
    navigation: {
      prevEl: '.product-card__navigation--prev',
      nextEl: '.product-card__navigation--next',
      disabledClass: 'product-card__navigation--disabled',
      enabled: true,
    },
    pagination: {
      el: '.product-card__pagination',
      enabled: true,
    },
    breakpoints: {
      0: {
        navigation: {
          enabled: false,
        },
      },
      766: {
        navigation: {
          enabled: true,
        },
      },
    },
  })
}

window.addEventListener('DOMContentLoaded', () => {
  /**
   * Allow us to initialize a swiper when the user hovers the picture of a card
   * It will work for static elements or those added with react
   *
   * It will only work on tablet and desktop (not mobile)
   */
  document.addEventListener('mouseover', (event) => {
    if (isMobile()) return

    const { target } = event
    const swiper = target.closest('.product-card__swiper')

    if (!swiper) return

    const { productCardPictures: swiperPicturesJson, initialized, hasLargeLayout } = swiper.dataset
    if (initialized === 'true') return

    const swiperPictures = JSON.parse(swiperPicturesJson)
    if (!Array.isArray(swiperPictures) || swiperPictures.length < 2) return

    addPaginationToSwiper(swiper)
    addMissingSlideToSwiper(swiper, swiperPictures, hasLargeLayout)

    initializeSwiper(swiper)

    swiper.setAttribute('data-initialized', 'true')
  })
})
