import { isTouch } from '../utils/responsive'

window.addEventListener('DOMContentLoaded', () => {
  const accordions = document.querySelectorAll('.faq ul > li')
  accordions.forEach((accordion, index) => {
    const span = accordion.querySelector('span')
    if (span)
      span.addEventListener('click', () => {
        accordions.forEach((question) => question.classList.remove('open'))
        accordion.classList.add('open')
      })
    if (index === 0 && !isTouch()) accordion.classList.add('open')
  })
})
