import { debounce } from 'lodash'
import { isTouch } from '../../utils/responsive'

class MenuDropdown {
  constructor() {
    this.menuTriggers = Array.from(document.querySelectorAll('[data-menu-dropdown-trigger]'))
    this.menuMainCategories = Array.from(document.querySelectorAll('[data-menu-main-category]'))

    this.menuDropdown = document.getElementById('menu-dropdown')

    if (!this.menuDropdown) return

    this.menuDropdownContent = document.getElementById('menu-dropdown-content')
    this.background = document.getElementById('menu-dropdown-background')

    this.listLevels = Array.from(this.menuDropdownContent.querySelectorAll('[data-menu-dropdown-level]'))
    this.items = Array.from(this.menuDropdownContent.querySelectorAll('[data-menu-dropdown-item]'))
    this.currentSlideLevel = 1

    this.menuDropdownIsOpen = false

    this.initEvents()
  }

  initEvents = () => {
    this.items.forEach((item) => {
      item.addEventListener(
        'click',
        debounce((e) => this.handleItemClick(e, item), 500, {
          leading: true, // Detect only the first click (to prevent too much transition)
          trailing: false,
        })
      )
      item.addEventListener('mouseover', (e) => {
        if (!isTouch()) return
        this.handleItemClick(e, item)
      })
    })

    this.menuTriggers.forEach((trigger) =>
      trigger.addEventListener('click', (e) => {
        if (!isTouch()) this.handleActiveMenuItem(trigger)
        this.toggleDropdown(e, trigger)
      })
    )

    document.addEventListener('click', this.handleDocumentClick)
    window.addEventListener('resize', this.handleResize)
  }

  /**
   * Handle Click on the document :we will close the menuDropdown if the click happens in specifics places
   *
   * @returns {(function(*): void)|*}
   */
  handleDocumentClick = (e) => {
    if (e.target.closest('[data-menu-dropdown-close-trigger]')) {
      this.closeMenuDropdown()
    }
  }

  handleResize = () => {
    if (this.menuDropdownIsOpen) this.closeMenuDropdown()
  }

  openMenuDropdown = (openOnListId = null) => {
    document.documentElement.classList.add('menu-dropdown-open')
    this.menuDropdown.classList.add('menu-dropdown--open')
    this.background.classList.add('menu-dropdown__background--visible')

    if (openOnListId) {
      const list = document.querySelector(`[data-menu-dropdown-item-parent-id="${openOnListId}"]`)
      const listContainer = list.closest('[data-menu-dropdown-level]')
      const level = listContainer.dataset.menuDropdownLevel

      this.currentSlideLevel = parseInt(level, 10)

      this.hideLevel(this.currentSlideLevel)
      this.hideLevel(this.currentSlideLevel + 1)

      if (!isTouch()) {
        this.hideLevel(this.currentSlideLevel - 1)
      }

      if (isTouch()) {
        const { transition } = this.menuDropdownContent.style
        this.menuDropdownContent.style.transition = 'none'
        this.menuDropdownContent.style.transform = `translateX(-${(this.currentSlideLevel - 1) * 100}%)`
        setTimeout(() => {
          this.menuDropdownContent.style.transition = transition
        }, 350)
      }

      list.classList.remove('menu-dropdown__items--hidden')
    }

    this.menuDropdownIsOpen = true
  }

  closeMenuDropdown = () => {
    this.handleActiveMenuItem(null)

    this.currentSlideLevel = 1
    this.menuDropdownContent.style.transform = 'translateX(0)'

    document.documentElement.classList.remove('menu-dropdown-open')
    this.menuDropdown.classList.remove('menu-dropdown--open')
    this.background.classList.remove('menu-dropdown__background--visible')

    this.hideLevel(2)
    this.hideLevel(3)
    this.showLevel(1)

    this.menuDropdownIsOpen = false
  }

  /**
   *
   * @param e
   * @param trigger
   */
  toggleDropdown = (e, trigger) => {
    e.preventDefault()

    // Security to prevent to handle dropdown when the main-header AutocompleteSearchbox is open
    if (document.documentElement.classList.contains('searchbox-mobile--open')) return

    // quick fix to ensure that we do not display the menu too far down
    window.scrollTo(0, 0)

    const { menuItemId } = trigger?.dataset || {}

    if (this.menuDropdownIsOpen && !menuItemId) {
      this.closeMenuDropdown()
    } else {
      this.openMenuDropdown(menuItemId)
    }
  }

  /**
   * @param {?MouseEvent} e
   * @param {HTMLElement} item
   */
  handleItemClick = (e, item) => {
    if (e && e.type === 'mouseover' && isTouch()) return

    const { menuDropdownItemId, menuDropdownItemIsLink, levelNb } = item.dataset

    if (levelNb < 3 && isTouch()) e.preventDefault()

    if (menuDropdownItemIsLink !== undefined) return

    if (e.target.closest('[data-menu-dropdown-item-back]')) {
      this.handleBackClick()
      return
    }

    if (isTouch()) {
      const child = document.querySelector(`[data-menu-dropdown-item-parent-id="${menuDropdownItemId}"]`)

      child.classList.remove('menu-dropdown__items--hidden')
      this.menuDropdownContent.style.transform = `translateX(-${this.currentSlideLevel * 100}%)`

      this.currentSlideLevel += 1
    }
  }

  handleBackClick = () => {
    this.currentSlideLevel -= 1

    if (this.currentSlideLevel === 1) {
      this.menuDropdownContent.style.transform = 'translateX(0)'
    } else {
      this.menuDropdownContent.style.transform = `translateX(-${(this.currentSlideLevel - 1) * 100}%)`
    }

    setTimeout(() => {
      this.hideLevel(this.currentSlideLevel + 1)
    }, 350)
  }

  hideLevel = (level) => {
    // Après la transition vers l'arrère, on réinitialise le niveau supérieur en cachant les enfants de nouveau.
    const levelContainer = document.querySelector(`[data-menu-dropdown-level="${level}"]`)
    const children = Array.from(levelContainer.querySelectorAll('[data-menu-dropdown-item-parent]'))
    children.forEach((child) => child.classList.add('menu-dropdown__items--hidden'))
  }

  showLevel = (level) => {
    const levelContainer = document.querySelector(`[data-menu-dropdown-level="${level}"]`)
    const children = Array.from(levelContainer.querySelectorAll('[data-menu-dropdown-item-parent]'))
    children.forEach((child) => child.classList.remove('menu-dropdown__items--hidden'))
  }

  handleActiveMenuItem = (trigger = null) => {
    this.menuMainCategories.forEach((item) => {
      item.classList.remove('active')
    })

    if (trigger) trigger.classList.add('active')
  }
}

const initMenuDropdown = () => new MenuDropdown()

/*
  Utile pour fermer le menu depuis d'autres composants
*/
export function closeMenuDropdown() {
  const menuDropdown = document.getElementById('menu-dropdown')
  const background = document.getElementById('menu-dropdown-background')
  const activeItem = document.querySelector('.main-header-menu__categories-link.active')

  if (menuDropdown) menuDropdown.classList.remove('menu-dropdown--open')
  if (background) background.classList.remove('menu-dropdown__background--visible')
  if (activeItem) activeItem.classList.remove('active')
}

window.addEventListener('DOMContentLoaded', initMenuDropdown)
