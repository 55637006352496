import { isTouch } from "../utils/responsive";

window.addEventListener('DOMContentLoaded', () => {
  const filtersPanel = document.querySelector(".filters_panel__panel");
  const openFiltersButton = document.querySelector(".filters_panel__open-button");
  const headerCloseFiltersButton = document.querySelector(".filters_panel__panel-header__button");
  const footerCloseFiltersButton = document.querySelector(".filters_panel__panel-footer__primary-button");
  const footerEraseFiltersButton = document.querySelector(".filters_panel__panel-footer__erase-button");
  const filterCountSpan = openFiltersButton?.querySelector('.filters_panel__open-button--count');
  const filters = filtersPanel?.querySelectorAll('.accordion_filter__content > *');
  const toggleClassname = "filters_panel__panel--show-mobile-filters-panel";

  openFiltersButton?.addEventListener("click", function () {
    filtersPanel.classList.toggle(toggleClassname);
  });
  headerCloseFiltersButton?.addEventListener("click", function () {
    filtersPanel.classList.toggle(toggleClassname);
  });
  footerCloseFiltersButton?.addEventListener("click", function () {
    filtersPanel.classList.toggle(toggleClassname);
  });
  footerEraseFiltersButton?.addEventListener("click", function () {
    eraseFilters();
  });
  filters?.forEach(filter => {
    filter.addEventListener('click', updateFilterCount);
  });

  /**
   * TODO - handle other input types if necessary
   */
  function eraseFilters() {
    const filters = filtersPanel.querySelectorAll('.accordion_filter');
    if (filters && filters.length) {
      Array.from(filters).forEach(filter => {
        const inputs = filter.querySelectorAll('input');
        if (inputs && inputs.length) {
          Array.from(inputs).forEach(input => {
            switch (input.type) {
              case 'checkbox':
                input.checked = false;
                break;
              default:
                break;
            }

            history.replaceState({}, '', window.location.pathname);
            window.dispatchEvent(new Event('filtersPanelErasedFilters'));
          })
        }
      });
      filterCountSpan.textContent = '';
    }
  }

  /**
   * TODO - handle other input types if necessary
   */
  function updateFilterCount() {
    if (!filtersPanel) return;

    const checkedCheckboxes = filtersPanel.querySelectorAll('.accordion_filter input[type="checkbox"]:checked');
    const nbActiveFilters = checkedCheckboxes.length;

    if (nbActiveFilters > 0) filterCountSpan.textContent = `(${nbActiveFilters})`;
    else filterCountSpan.textContent = '';
  }

  /**
   * hide panel if opened when going over mobile resolution
   */
  window.addEventListener('resize', () => {
    if (!isTouch() && filtersPanel) {
      filtersPanel.classList.remove(toggleClassname);
    }
  });

  /**
   * Will count the number of active filters
   * Delay it because checkboxes first have to listen to variables or url search params
   */
  setTimeout(() => updateFilterCount(), 500);
});
