const STUCK_ELEMENTS = [
	'.article_card__link__figure',
	'.product-card__swiper',
];

const STUCK_MODIFIERS = {
	'left': 'stuck-left-border',
	'right': 'stuck-right-border',
};

/**
 * This function adds a class to the element if it is stuck to the left or right side of the screen removing its border
 */
window.addEventListener('DOMContentLoaded', () => {
	function isElementStuckToLeft(element) {
		const rect = element.getBoundingClientRect();
		return rect.left <= 0;
	}

	function isElementStuckToRight(element) {
		const rect = element.getBoundingClientRect();
		return rect.right >= window.innerWidth;
	}

	function handleElementsStuckToSide(selector) {
		selector.forEach((elementSelector) => {
			const elements = document.querySelectorAll(elementSelector);
			if (elements && elements.length) {
				elements.forEach((element) => {

					if (isElementStuckToLeft(element)) {
						element.classList.add(STUCK_MODIFIERS.left);
					} else if (isElementStuckToRight(element)) {
						element.classList.add(STUCK_MODIFIERS.right);
					} else {
						element.classList.remove(STUCK_MODIFIERS.left);
						element.classList.remove(STUCK_MODIFIERS.right);
					}
				});
			}
		});
	}

	window.addEventListener('resize', () => {
		handleElementsStuckToSide(STUCK_ELEMENTS);
	});

	window.addEventListener('load', () => {
		handleElementsStuckToSide(STUCK_ELEMENTS);
	});
});
