import React, { forwardRef, lazy, Suspense } from 'react'
import ReactDOM from 'react-dom'
import { IntlProvider } from 'react-intl'
import localeData from '../../../public/locales/data.json'
import { getCurrentLocale } from '../utils/translation'
import { CustomToaster } from '../react_component/Common/Toast'

const locale = getCurrentLocale()

// Try full locale, try locale without region code, fallback to 'fr'
const messages = localeData[locale] || localeData.fr

window.asyncElementsLoading = false

window.showElementsAsync = (container, component, type, opts, config) => {
  if (document.getElementById(component) === null) {
    const LazyElement = lazy(() => import(`../react_component/${type}.js`))

    const Component = forwardRef((props, ref) => <LazyElement ref={ref} component={component} {...opts} />)

    Component.displayName = 'Component'

    const { ignoreToaster } = config || {}

    // Render our LazyPopup asynchronously
    ReactDOM.render(
      <IntlProvider
        locale={locale}
        messages={messages}
        defaultRichTextElements={{
          strong: (chunks) => <b>{chunks}</b>,
          span: (chunks) => <span>{chunks}</span>,
          normal: (chunks) => <span className="has-text-weight-normal">{chunks}</span>,
          br: () => <br />,
          b: (chunks) => <b>{chunks}</b>,
        }}
      >
        <Suspense fallback={<div className="" />}>
          {!ignoreToaster && <CustomToaster />}
          <Component />
        </Suspense>
      </IntlProvider>,
      document.getElementById(container)
    )

    // Tell we are loading the popup
    window.asyncElementsLoading = true
  } else {
    console.log(`Load previous rendered ${type}.`)
    window.asyncElements.show()
  }
}
