import { containsQueryParam } from '../../utils/url'

function displayIcon() {
  window.showElementsAsync(
    'saved-searches-header-icon-react',
    'saved-searches-header-icon-component',
    'Common/SavedSearchesIcon'
  )
}

/*
  Updates the bell icon in the main header with a React component.
  - This component will fetch the number of saved searches that have new products available.
  - If saved_search_id is present in the URL, the icon will be displayed after 1.5 seconds because React <SaveSearch /> catalog component will first decrement the cached count.
*/
function displaySavedSearchesHeaderIcon() {
  const savedSearchIconContainer = document.getElementById('saved-searches-header-icon')
  if (savedSearchIconContainer) {
    if (containsQueryParam('saved_search_id')) {
      setTimeout(() => {
        displayIcon()
      }, 1500)
    } else displayIcon()
  }
}

window.addEventListener('DOMContentLoaded', () => displaySavedSearchesHeaderIcon())
