import detectIt from 'detect-it'
import { isMobile } from '../utils/responsive'

/**
 * This "object" allow to configure the interaction with the responsive carousel
 * @param container the container of the carousel
 * @param options an array of options
 */
export default class Carousel {
  constructor(carousel, options) {
    if (carousel === null) {
      return
    }
    // Default values
    this.arrowLeft = null
    this.arrowRight = null
    this.dots = null
    this.carousel = carousel
    this.items = []
    this.currentPosition = 0
    this.tempo = null
    this.arrowAvailable = undefined !== options.arrow ? options.arrow : true
    this.arrowOnMobileAvailable = undefined !== options.arrowOnMobile ? options.arrowOnMobile : false
    this.dotsAvailable = undefined !== options.dots ? options.dots : false
    this.overflowOpacityAvailable = undefined !== options.overflow ? options.overflow : false
    this.increaseTranslation = 0
    this.startMouseX = -1
    this.swipe = false
    this.shownItems = 0
    this.withLinks = this.carousel.querySelectorAll('.with-link')
    this.navigationPrevented = false
    this.autoplayDelay = options.autoplayDelay || null
    this.disableSwipeMobile = options.disableSwipeMobile || false

    // Binding of all methods
    this.updateDots = this.updateDots.bind(this)
    this.addDots = this.addDots.bind(this)
    this.updateArrows = this.updateArrows.bind(this)
    this.addArrows = this.addArrows.bind(this)
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
    this.move = this.move.bind(this)
    this.moveTo = this.moveTo.bind(this)
    this.refreshView = this.refreshView.bind(this)
    this.getShownItemsCount = this.getShownItemsCount.bind(this)
    this.refreshItems = this.refreshItems.bind(this)
    this.handleResize = this.handleResize.bind(this)
    this.handleMouseove = this.handleMouseove.bind(this)
    this.handleMousedown = this.handleMousedown.bind(this)
    this.handleMouseup = this.handleMouseup.bind(this)
    this.checkCurrentPosition = this.checkCurrentPosition.bind(this)
    this.preventNavigation = this.preventNavigation.bind(this)
    this.allowNavigation = this.allowNavigation.bind(this)
    this.autoplay = this.autoplay.bind(this)

    // Initialisation
    this.refreshItems()
    this.getShownItemsCount()
    this.carousel.classList.add('transition')

    // Add Arrows
    this.addArrows()
    this.updateArrows()

    // Ajout dots
    this.addDots()
    this.updateDots()

    // Refresh for opacity
    this.refreshView()

    // Handle window resize
    window.addEventListener('resize', this.handleResize)

    // Handle swipable carousel
    this.carousel.addEventListener('mousedown', this.handleMousedown)
    this.carousel.addEventListener('mousemove', this.handleMouseove)
    document.addEventListener('mouseup', this.handleMouseup)
    document.addEventListener('dragend', this.handleMouseup)

    this.carousel.addEventListener(
      'touchstart',
      this.handleMousedown,
      detectIt.passiveEvents ? { passive: true } : false
    )
    this.carousel.addEventListener('touchmove', this.handleMouseove, detectIt.passiveEvents ? { passive: true } : false)
    document.addEventListener('touchend', this.handleMouseup, detectIt.passiveEvents ? { passive: true } : false)
    if (this.autoplayDelay) this.autoplay()
  }

  handleResize() {
    this.carousel.classList.remove('transition')
    // eslint-disable-next-line no-unused-expressions
    this.carousel.offsetWidth // Used to flush the DOM

    this.refreshView()

    clearInterval(this.tempo)

    // TODO find a better way to do this
    this.tempo = window.setTimeout(() => {
      this.refreshView()
      this.carousel.classList.add('transition')
      // eslint-disable-next-line no-unused-expressions
      this.carousel.offsetWidth // Used to flush the DOM
    }, 50)
  }

  checkCurrentPosition() {
    this.getShownItemsCount()

    if (this.shownItems + this.currentPosition > this.items.length)
      this.currentPosition = this.items.length - this.shownItems

    if (this.currentPosition < 0) this.currentPosition = 0
  }

  // Get the items of our carousel (and initialize their position)
  refreshItems() {
    this.items = Array.from(this.carousel.childNodes)
      .map((node) => (Node.ELEMENT_NODE === node.nodeType ? node : null))
      .filter((element) => element)
      .map((node) => {
        node.style.left = 0
        return node
      })
  }

  // Get the number of visible items
  getShownItemsCount() {
    this.shownItems = Math.round(this.carousel.offsetWidth / this.items[0].offsetWidth)
    return this.shownItems
  }

  // Stop an event (default and propagation)
  static stopEvent(event) {
    if (event == null) return

    event.preventDefault()
    event.stopPropagation()
  }

  // Update the positions of items
  refreshView() {
    this.checkCurrentPosition()
    const translation = `${-((100 / this.shownItems) * this.currentPosition) + this.increaseTranslation * 100}%`

    const nbItems = this.getShownItemsCount()
    const cur = this.currentPosition
    const hide = this.overflowOpacityAvailable
    const { previous } = this
    const { next } = this

    if (hide) this.carousel.style.overflow = 'visible'

    this.items.forEach((item, key) => {
      item.style.left = translation

      if (hide && (key + 1 - cur > nbItems || key < cur)) {
        item.classList.add('hidden-item')
        if (key + 1 - cur > nbItems) item.addEventListener('click', next)
        else if (key < cur) item.addEventListener('click', previous)
      } else {
        item.classList.remove('hidden-item')
        item.removeEventListener('click', previous)
        item.removeEventListener('click', next)
      }
    })

    this.updateArrows()
    this.updateDots()
  }

  // Try scroll the items
  move(direction) {
    this.currentPosition -= direction
    this.refreshView()
  }

  // Try to show the next item (Arrow Only)
  next(event) {
    Carousel.stopEvent(event)
    this.move(-1)
  }

  // Try to show the previous item (Arrow Only)
  previous(event) {
    Carousel.stopEvent(event)
    this.move(1)
  }

  // Move to specific position (Dot Only)
  moveTo(event, nextPosition) {
    Carousel.stopEvent(event)

    if (this.currentPosition === nextPosition) return

    this.currentPosition = nextPosition

    this.refreshView()
  }

  // Add arrows
  addArrows() {
    if (!this.arrowAvailable) return

    this.carousel.className += ' has-arrow'

    // Add Arrow Left
    this.arrowLeft = this.carousel.appendChild(document.createElement('div'))
    this.arrowLeft.className = 'column arrow is-left hidden'
    this.arrowLeft.innerHTML = '<button type="button" class="button-reset ci-arrow-left-primary"></button>'

    this.arrowLeft.addEventListener('click', () => {
      this.autoplayDelay = null
      this.previous()
    })

    // Add Right Arrow
    this.arrowRight = this.carousel.appendChild(document.createElement('div'))
    this.arrowRight.className = `column arrow is-right${
      this.currentPosition + this.shownItems === this.items.length ? 'hidden' : ''
    }`
    this.arrowRight.innerHTML = '<button type="button" class="button-reset ci-arrow-right-primary"></button>'

    this.arrowRight.addEventListener('click', () => {
      this.autoplayDelay = null
      this.next()
    })
  }

  // Update arrows width and position
  updateArrows() {
    if (!this.arrowAvailable) return

    if (this.arrowAvailable) {
      // hide arrow left if currentPosition is 0
      if (this.currentPosition === 0) {
        this.arrowLeft.className = 'column arrow is-left hidden'
      } else {
        this.arrowLeft.className = 'column arrow is-left'
      }

      if (this.currentPosition + this.shownItems >= this.items.length) {
        this.arrowRight.className = 'column arrow is-right hidden'
      } else {
        this.arrowRight.className = 'column arrow is-right'
      }

      if (!this.arrowOnMobileAvailable) {
        this.arrowLeft.className += ' is-hidden-mobile'
        this.arrowRight.className += ' is-hidden-mobile'
      }
    }
  }

  // Add dots
  addDots() {
    if (!this.dotsAvailable) return

    this.dots = document.createElement('ul')
    this.carousel.insertAdjacentElement('afterend', this.dots)
    this.dots.className = 'dots'

    this.items.forEach((item, index) => {
      const dot = document.createElement('li')
      dot.addEventListener('click', (event) => {
        this.autoplayDelay = null
        this.moveTo(event, index)
      })
      this.dots.appendChild(dot)
    })
  }

  // Update Dots style
  updateDots() {
    if (!this.dotsAvailable) return

    this.dots.childNodes.forEach((dot, index) => {
      if (this.currentPosition === index) {
        dot.className = 'is-selected'
      } else {
        dot.className = ''
      }
    })
  }

  // Get event or touch X position with an event
  static getClientX(event) {
    return event.clientX || (event.touches ? event.touches[0].clientX : 0)
  }

  // Handle mouse or touch event (move) on the mobile menu
  handleMouseove(event) {
    if (this.disableSwipeMobile === true && isMobile()) return
    if (this.startMouseX < 0) return

    this.carousel.classList.remove('transition')

    this.increaseTranslation = -((this.startMouseX - Carousel.getClientX(event)) / this.carousel.offsetWidth)

    if (this.increaseTranslation > 0.05 || this.increaseTranslation < -0.05) {
      this.swipe = true
      this.preventNavigation()
    }
    this.refreshView()
  }

  // Handle mouse or touch event (start) on the mobile menu
  handleMousedown(event) {
    if (this.disableSwipeMobile === true && isMobile()) return
    this.autoplayDelay = null
    this.startMouseX = Carousel.getClientX(event)
  }

  // Handle mouse or touch event (end) on the mobile menu
  handleMouseup(event) {
    if (this.disableSwipeMobile === true && isMobile()) return
    this.autoplayDelay = null
    this.carousel.classList.add('transition')

    if (this.startMouseX < 0) return

    if (this.swipe === true) Carousel.stopEvent(event)

    this.startMouseX = -1

    if (this.increaseTranslation > 0.1) {
      this.increaseTranslation = 0
      this.previous()
      // this.mobilePrevious();
    } else if (this.increaseTranslation < -0.1) {
      this.increaseTranslation = 0
      this.next()
    } else {
      this.increaseTranslation = 0
      this.refreshView()
    }

    window.setTimeout(() => {
      this.swipe = false
      this.allowNavigation()
    }, 100)
  }

  preventNavigation() {
    if (this.navigationPrevented) return

    this.withLinks.forEach((item) => {
      item.dataset.preventNavigation = true
    })
    this.navigationPrevented = true
  }

  allowNavigation() {
    this.withLinks.forEach((item) => {
      item.dataset.preventNavigation = false
    })
    this.navigationPrevented = false
  }

  autoplay() {
    window.setTimeout(() => {
      if (this.autoplayDelay) {
        if (this.items.length > this.currentPosition + 1) this.next()
        else this.moveTo(null, 0)
        this.autoplay()
      }
    }, this.autoplayDelay)
  }
}

window.Carousel = Carousel
