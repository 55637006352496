import { isTouch } from '../utils/responsive'

window.addEventListener('DOMContentLoaded', () => {
  const accordionContainers = document.querySelectorAll('.accordion_filter')

  function toggleIcon(header, content) {
    const icon = header.querySelector('i')
    if (content.classList.contains('show')) {
      icon.classList.replace('icon--simple-plus', 'icon--simple-minus')
    } else {
      icon.classList.replace('icon--simple-minus', 'icon--simple-plus')
    }
  }

  Array.from(accordionContainers).forEach((container) => {
    const header = container.querySelector('.accordion_filter__header')
    const content = container.querySelector('.accordion_filter__content')

    if (header && content) {
      header.addEventListener('click', () => {
        content.classList.toggle('show')
        toggleIcon(header, content)
      })

      // default icon is minus, if content is not shown at load we have to toggle icon to a 'plus'
      if (!content.classList.contains('show')) {
        toggleIcon(header, content)
      }
    }
  })

  // On mobile, hide filter label when there is only one filter
  function handleHideMobileUniqueFilterLabel() {
    const uniqueFilter = accordionContainers[0]
    const header = uniqueFilter?.querySelector('.accordion_filter__header')
    const content = uniqueFilter?.querySelector('.accordion_filter__content')

    if (uniqueFilter && header && content && accordionContainers.length === 1) {
      if (isTouch()) {
        header.classList.add('is-hidden')
        content.classList.add('show')
      } else {
        header.classList.remove('is-hidden')
        toggleIcon(header, content)
      }
    }
  }

  window.addEventListener('resize', () => {
    handleHideMobileUniqueFilterLabel()
  })

  handleHideMobileUniqueFilterLabel()
})
