import axios from 'axios'
import config from '../config'
import { getHeaders } from './constants'
import { handleAxiosError } from '../utils/error'

export function socialLogin(data) {
  return fetch(`${config.api}/users/social`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error)
    })
}

export function refreshToken(data) {
  return fetch(`${config.api}/users/refresh-token`, {
    method: 'POST',
    headers: {
      ...getHeaders(),
      Authorization: `Bearer ${data.token}`,
    },
    body: JSON.stringify({ email: data.email }),
  })
    .then((response) => response.json())
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error)
    })
}

export function me() {
  return fetch(`/me`, {
    method: 'GET',
  })
    .then((response) => response.json())
    .catch((error) => error.json())
}

export function geolocation(ip) {
  return fetch(`/geolocation`, {
    method: 'POST',
    body: JSON.stringify({ ip }),
  })
    .then((response) => response.json())
    .catch((error) => error.json())
}

export function login(data) {
  return fetch(`${config.api}/login_check`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .catch((error) => error.json())
}

export function register(data) {
  return fetch(`${config.api}/users`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .catch((error) => error.json())
}

export function exists(email) {
  return fetch(`${config.api}/users/exists`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({
      email,
    }),
  })
    .then((response) => response.json())
    .catch((error) => error.json())
}

export function addAddress(address) {
  return fetch(`${config.api}/addresses`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(address),
  })
    .then((response) => response.json())
    .catch((error) => error.json())
}

export function putAddress(address) {
  return address.id && address.id !== ''
    ? fetch(`${config.api}/addresses/${address.id}`, {
        method: 'PUT',
        headers: getHeaders(),
        body: JSON.stringify(address),
      })
        .then((response) => response.json())
        .catch((error) => error.json())
    : addAddress(address)
}

export function updateUser({ id, email, firstName, lastName, birthday }) {
  return fetch(`${config.api}/users/${id}`, {
    method: 'PUT',
    headers: getHeaders(),
    body: JSON.stringify({
      email,
      firstName,
      lastName,
      birthday,
    }),
  })
    .then((response) => response.json())
    .catch((error) => error.json())
}

export function changePassword({ actualPassword, newPassword }) {
  return fetch(`${config.api}/users/change-password`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({
      password: newPassword,
      oldPassword: actualPassword,
    }),
  })
    .then((response) => response.json())
    .catch((error) => error.json())
}

export function updateUserNotifications({ id, advertising }) {
  return fetch(`${config.api}/users/${id}/notifications`, {
    method: 'PUT',
    headers: getHeaders(),
    body: JSON.stringify({
      advertising,
    }),
  })
    .then((response) => response.json())
    .catch((error) => error.json())
}

export async function addProductToWishlist(userId, productId, productSlug, type = 'favorite') {
  return axios
    .post(`/users/${userId}/wishlist/add`, { productId, productSlug, type }, { headers: await getHeaders() })
    .then((response) => response)
    .catch((error) => error.json())
}

export async function removeProductFromWishlist(userId, productId, productSlug) {
  return axios
    .delete(`/users/${userId}/wishlist/remove`, {
      headers: await getHeaders(),
      data: { productId, productSlug },
    })
    .then((response) => response)
    .catch((error) => error.json())
}

export async function deactivateUserAccount(userId, deactivateReason) {
  return axios
    .delete(`/api/users/${userId}/deactivate`, {
      headers: await getHeaders(),
      data: { deactivateReason },
    })
    .then((response) => response)
    .catch((error) => {
      throw handleAxiosError(error)
    })
}

export async function getUserReviews(userId) {
  return axios
    .get(`/api/users/${userId}/reviews`, {})
    .then((response) => {
      const { data } = response
      return data
    })
    .catch((error) => {
      throw handleAxiosError(error)
    })
}
