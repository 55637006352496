import axios from 'axios'
import Cookies from 'js-cookie'

const updateUserDevice = () => {
  axios
    .post('/users/parse-device', {
      javaEnabled: false,
      javascriptEnabled: true,
      language: window.navigator.language,
      colorDepth: window.screen.colorDepth,
      screenHeight: window.screen.height,
      screenWidth: window.screen.width,
      timeZoneOffset: new Date().getTimezoneOffset(),
      userAgent: window.navigator.userAgent,
    })
    .then((res) =>
      Cookies.set('userParsedDevice', JSON.stringify(res.data), { expires: 365, sameSite: 'none', secure: true })
    )
}

export default updateUserDevice
