import './dropdown/notifications'
import { isMobile, isTouch } from '../../utils/responsive'
import { hideChatWidget } from '../../plugins/chat-widget'
import { hideZIndexes, showZIndexes } from '../products/layout'

function isElementInViewport(el) {
  const rect = el.getBoundingClientRect()

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

function handleStickRight(el, mode) {
  if (mode === 'add') {
    // stick to the right of the window
    el.style.position = 'fixed'
    el.style.right = 0
    el.style.left = 'auto'
    el.style.transform = 'none'
  } else if (mode === 'remove') {
    // remove css for stick to the right of the window
    el.style.removeProperty('position')
    el.style.removeProperty('right')
    el.style.removeProperty('left')
    el.style.removeProperty('transform')
  }
}

function handleDropdown(e) {
  if (!e.target.closest('[data-dropdown]')) {
    document.querySelectorAll('[data-dropdown].active').forEach((clickDropdownContainer) => {
      clickDropdownContainer.classList.remove('active')
      showZIndexes()
      handleStickRight(clickDropdownContainer.querySelector('.dropdown'), 'remove')
    })
  }
}

/**
 * Only use with the data-fullscreen-mobile options.
 *
 * This function prevents to show the full screen dropdown again when the user
 * resizes the window, from mobile to tablet and resize back from tablet to mobile.
 *
 * (in the case that this resize is done with a container that was visible on mobile)
 *
 */
function handleResize() {
  if (!isMobile()) {
    window.removeEventListener('resize', handleResize)
    document.documentElement.classList.remove('menu--open')
    document.querySelectorAll('[data-fullscreen-mobile]').forEach((container) => container.classList.remove('active'))
  }
}

/**
 * Add dropdown interactions to a container with data-dropdown attribute
 * This container must contains a data-handle element and a .dropdown container
 * The data-handle will be used to show or hide the .dropdown container on click or mouseover events
 *
 * specifics options :
 * - noSticky : By default, the container become sticky when it is out of screen. This option prevents this behavior
 * - fullScreenMobile :  make the window not scrollable when the container is in mobile mode
 * - dataFocus : focus the first meet input / textarea inside the opened dropdown
 *
 * @param {HTMLElement | Document} container
 * @todo  Add and handle keypress event too
 *
 * eslint-disable-next-line import/prefer-default-export
 */
const initDropdowns = (container) => {
  const dropdownContainers = container.querySelectorAll('[data-dropdown]')

  dropdownContainers.forEach((clickDropdownContainer) => {
    const handledEvent = clickDropdownContainer.hasAttribute('data-hover') ? 'mouseover' : 'click'
    const noSticky = clickDropdownContainer.hasAttribute('data-no-sticky')
    const fullScreenMobile = clickDropdownContainer.hasAttribute('data-fullscreen-mobile')
    const focusFirstInput = clickDropdownContainer.hasAttribute('data-focus')
    const keepZIndex = clickDropdownContainer.hasAttribute('data-keep-z-index')

    clickDropdownContainer.querySelector('[data-handle]').addEventListener(handledEvent, (e) => {
      e.preventDefault()
      hideChatWidget()
      if (isTouch() && handledEvent === 'click' && !keepZIndex) hideZIndexes()

      // hide others dropdowns
      dropdownContainers.forEach((containerContainer) => {
        if (containerContainer !== e.currentTarget) containerContainer.classList.remove('active')
      })

      const dropdown = clickDropdownContainer.querySelector('.dropdown')
      const isOffScreen = !isElementInViewport(dropdown)

      if (handledEvent === 'mouseover' && isOffScreen) {
        handleStickRight(dropdown, 'add')
      } else {
        window.removeEventListener(handledEvent, handleDropdown)

        clickDropdownContainer.classList.toggle('active')

        // Focus the first input inside the dropdown
        if (focusFirstInput && clickDropdownContainer.classList.contains('active')) {
          const firstInput = dropdown.querySelector('input[type=text], textarea')
          if (firstInput) firstInput.focus()
        }

        if (fullScreenMobile) {
          if (isMobile() && clickDropdownContainer.classList.contains('active')) {
            document.documentElement.classList.add('menu--open')
            window.addEventListener('resize', handleResize)
          } else {
            document.documentElement.classList.remove('menu--open')
            window.addEventListener('resize', handleResize)
          }
        }

        if (isOffScreen && !noSticky) handleStickRight(dropdown, 'add')

        window.addEventListener(handledEvent, handleDropdown)
      }
    })

    clickDropdownContainer.querySelectorAll('[data-dropdown-close]').forEach((button) =>
      button.addEventListener('click', () => {
        document.documentElement.classList.remove('menu--open')
        clickDropdownContainer.classList.remove('active')
        if (fullScreenMobile) {
          window.removeEventListener('resize', handleResize)
        }
      })
    )
  })
}

export default initDropdowns
