export default {
  api: process.env.PUBLIC_API_URL,
  env: process.env.APP_ENV,
  oney: {
    merchantGUID: process.env.ONEY_MERCHANT_GUID,
    businessCode3x: process.env.ONEY_BUSINESS_TRANSACTION_CODE_3X,
    businessCode4x: process.env.ONEY_BUSINESS_TRANSACTION_CODE_4X,
    businessCode10x: process.env.ONEY_BUSINESS_TRANSACTION_CODE_10X,
  },
  alma: {
    ALMA_MIN_EURO: process.env.ALMA_MIN_EURO,
    ALMA_MAX_EURO: process.env.ALMA_MAX_EURO,
    ALMA_CREDIT_X10_USER_RATE_PERCENTAGE: process.env.ALMA_CREDIT_X10_USER_RATE_PERCENTAGE,
    ALMA_MERCHANT_ID: process.env.ALMA_MERCHANT_ID,
  },
  reservationAmount: process.env.RESERVATION_AMOUNT,
  sentryDsn: process.env.SENTRY_DSN,
  version: process.env.APP_VERSION,
  googleRecaptchaSiteKey: process.env.GOOGLE_RECAPTCHA_SITE_KEY,
}

export const CATALOG_MAX_PRODUCT_PER_PAGE = 60
export const CATALOG_ADD_MIXTE_REQUIRED = ['Homme', 'Femme']
