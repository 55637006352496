import '../../css/component/with_link.sass'

window.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.with-link').forEach((item) => {
    if (item.dataset.link !== null) {
      item.classList.add('is-clickable')

      item.addEventListener('click', (event) => {
        const childrenPreventedNavigation = event.target.closest('[data-prevent-navigation]')

        // Prevent location change for a cta / link etc. in the .with-link component
        if (childrenPreventedNavigation) {
          const { preventNavigation: childPreventNavigation } = childrenPreventedNavigation.dataset

          if (childPreventNavigation === 'true' || childPreventNavigation === true) return
        }

        if (item.dataset.preventNavigation !== 'true' && item.dataset.preventNavigation !== true)
          window.open(item.dataset.link, item.attributes.target || item.dataset.target || '_self')
      })
    }
  })
})
