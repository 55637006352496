const handleBannerCode = () => {
  const bannerCode = document.getElementById('banner-code')
  const copied = document.getElementById('banner-copied')

  if (bannerCode && navigator.clipboard) {
    bannerCode.classList.add('banner__code--pointer')

    bannerCode.addEventListener('click', (e) => {
      e.preventDefault()

      navigator.clipboard.writeText(bannerCode.dataset.code).then(() => {
        copied.classList.add('banner__copied--visible')
        window.setTimeout(() => copied.classList.remove('banner__copied--visible'), 1000)
      })
    })
  }
}

export const openPreorderModal = () => {
  const preorderButton = document.getElementById('preorder-button')
  if (preorderButton) preorderButton.click()
}

const handleHolidayReservationBanner = () => {
  const banner = document.getElementById('holiday-reservation-banner')

  if (banner && banner.classList.contains('banner--clickable')) {
    banner.addEventListener('click', (e) => {
      e.preventDefault()

      openPreorderModal()
    })
  }
}

window.addEventListener('DOMContentLoaded', () => {
  handleBannerCode()
  handleHolidayReservationBanner()
})
