/* eslint-disable import/prefer-default-export */
/* eslint-disable no-use-before-define */
window.addEventListener('DOMContentLoaded', () => {
  const triggers = document.querySelectorAll('[data-modal-id]')

  triggers.forEach((trigger) => {
    trigger.addEventListener('click', (e) => {
      e.preventDefault()

      openModal(trigger.dataset.modalId)
    })
  })
})

export function openModal(modalId) {
  const modal = document.querySelector(`.campsider-modal[data-id="${modalId}"]`)

  if (!modal) return

  document.documentElement.classList.add('modal--open')
  modal.classList.remove('hidden')

  const closeTriggers = modal.querySelectorAll('[data-close]')

  closeTriggers.forEach((closeTrigger) => {
    closeTrigger.removeEventListener('click', hideModal)
    closeTrigger.addEventListener('click', hideModal)
  })

  const reloadTriggers = modal.querySelectorAll('[data-reload]')

  reloadTriggers.forEach((reloadTrigger) => {
    reloadTrigger.removeEventListener('click', reloadPage)
    reloadTrigger.addEventListener('click', reloadPage)
  })
}

export function hideModal(e) {
  e.preventDefault()
  const trigger = e.currentTarget

  if (!trigger) return

  const modal = trigger.closest('.campsider-modal')

  if (!modal) return

  modal.classList.add('hidden')
  modal.classList.remove('loading')
  document.documentElement.classList.remove('modal--open')
}

function reloadPage(e) {
  e.preventDefault()
  window.location.reload()
}
