import Swiper, { Navigation, Autoplay } from 'swiper'

window.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById('announces-swiper')) {
    // eslint-disable-next-line no-new
    new Swiper('#announces-swiper', {
      modules: [Navigation, Autoplay],
      slidesPerView: 'auto',
      navigation: {
        enabled: false,
      },
      breakpoints: {
        0: { navigation: { enabled: true } },
        1024: { navigation: { enabled: false } },
      },
      autoplay: {
        delay: 5000,
      },
    })
  }
  if (document.querySelector('#js_ls_trigger')) {
    document.querySelector('#js_ls_trigger').addEventListener('click', (e) => {
      e.preventDefault()
      document.querySelector('#js_ls_menu').classList.toggle('is-hidden')
    })
    document.querySelector('.lang-switcher').addEventListener('mouseleave', () => {
      if (!document.querySelector('#js_ls_menu').classList.contains('is-hidden'))
        document.querySelector('#js_ls_menu').classList.add('is-hidden')
    })
  }
})
