const handlePasswordInputIcons = (input) => {
  const inputField = input.querySelector('input[type="password"]')

  if (!inputField) return

  const eye = input.querySelector('.input-item-right i')

  const EYE_CLASS = 'icon--eye'
  const CROSSED_EYE_CLASS = 'icon--eye-crossed'

  eye.addEventListener('click', () => {
    if (inputField.type === 'password') {
      inputField.type = 'text'
      eye.classList.remove(EYE_CLASS)
      eye.classList.add(CROSSED_EYE_CLASS)
    } else {
      inputField.type = 'password'
      eye.classList.remove(CROSSED_EYE_CLASS)
      eye.classList.add(EYE_CLASS)
    }
  })
}

window.addEventListener('DOMContentLoaded', () => {
  const inputs = document.querySelectorAll('.input-wrapper')

  Array.from(inputs).forEach((input) => {
    handlePasswordInputIcons(input)
  })
})
