import { addChat } from '../../api/Chat'

window.addEventListener('DOMContentLoaded', () => {
  const contactSellers = document.querySelectorAll('button.contact-seller')

  contactSellers.forEach((contactSeller) => {
    contactSeller.addEventListener('click', async () => {
      const { userId, redirection, productId } = contactSeller.dataset

      if (!userId) window.location.assign(redirection)

      try {
        const chat = await addChat(productId)
        window.location.assign(`${redirection}?chat=${chat.id}`)
      } catch (error) {
        console.error(error)
      }
    })
  })
})
