import { debounce } from 'lodash'
import { addProductToWishlist, removeProductFromWishlist } from '../api/User'

window.addEventListener('DOMContentLoaded', () => {
  let wishlistActions = []

  const addToWishlist = async (userId, productId, productSlug, type = 'favorite') => {
    try {
      const response = await addProductToWishlist(userId, productId, productSlug, type?.replace('-', '_'))

      if (response.status === 200 && typeof window.dataLayer === 'object') {
        const product = response.data
        window.dataLayer.push({ ecommerce: null })
        window.dataLayer.push({
          event: 'add_to_wishlist',
          ecommerce: {
            currency: 'EUR',
            value: product.price,
            items: [
              {
                item_id: product.id,
                item_name: product.title,
                item_brand: product.brand.name,
                item_category: product.categories[2].name,
                item_category2: product.categories[1].name,
                item_category3: product.category.name,
                price: product.price,
                quantity: 1,
                type,
              },
            ],
          },
        })
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }

  const removeFromWishlist = async (userId, productId, productSlug) => {
    try {
      await removeProductFromWishlist(userId, productId, productSlug)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }

  const updateUserWishlist = () => {
    const wishlistActionsToLaunch = wishlistActions
    wishlistActions = []

    wishlistActionsToLaunch.forEach((action) => {
      const { userId, productId, productSlug, wishlistButtonAction, wishlistType } = action

      if (wishlistButtonAction === 'add') {
        addToWishlist(userId, productId, productSlug, wishlistType)
      } else {
        removeFromWishlist(userId, productId, productSlug)
      }
    })
  }

  const debounceUpdateUserWishlist = debounce(updateUserWishlist, 1000, {
    leading: false,
    trailing: true,
  })

  const handlePriceDecreaseSubscribingContent = (wishlistButtonAction) => {
    const subscribeButton = document.querySelector('[data-wishlist-type="price-decrease"]')
    if (!subscribeButton) return

    const { wishlistButtonAriaAdd, wishlistButtonAriaRemove } = subscribeButton.dataset

    const span = subscribeButton.querySelector('span')
    if (wishlistButtonAction === 'add') {
      span.innerHTML = wishlistButtonAriaRemove
      setTimeout(() => {
        subscribeButton.setAttribute('data-wishlist-button-action', 'remove')
      }, 500)
    } else {
      span.innerHTML = wishlistButtonAriaAdd
      setTimeout(() => {
        subscribeButton.setAttribute('data-wishlist-button-action', 'add')
      }, 500)
    }
  }

  /**
   *
   * @param {HTMLButtonElement} clickedButton
   */
  const updateWishlistActions = (clickedButton) => {
    const {
      userId,
      productId,
      productSlug,
      wishlistButtonAction,
      wishlistButtonAriaAdd,
      wishlistButtonAriaRemove,
      wishlistType,
    } = clickedButton.dataset

    handlePriceDecreaseSubscribingContent(wishlistButtonAction)

    if (wishlistButtonAction === 'add') {
      clickedButton.setAttribute('aria-label', wishlistButtonAriaRemove)
      clickedButton.classList.add('button-wishlist--filled')
      setTimeout(() => {
        clickedButton.setAttribute('data-wishlist-button-action', 'remove')
      }, 500)
    } else {
      clickedButton.setAttribute('aria-label', wishlistButtonAriaAdd)
      clickedButton.classList.remove('button-wishlist--filled')
      setTimeout(() => {
        clickedButton.setAttribute('data-wishlist-button-action', 'add')
      }, 500)
    }

    const actionsToDo = wishlistActions.filter((action) => action.productId !== productId)

    if (actionsToDo.length !== wishlistActions.length) {
      wishlistActions = actionsToDo
      return null
    }

    wishlistActions.push({ wishlistButtonAction, userId, productId, productSlug, wishlistType })
    debounceUpdateUserWishlist()
    return null
  }

  /**
   *
   * @param {HTMLButtonElement} redirectButton
   */
  const handleRedirect = (redirectButton) => {
    const { wishlistButtonRedirect } = redirectButton.dataset

    if (!wishlistButtonRedirect) return

    window.location.assign(wishlistButtonRedirect)
  }

  const handleWishlistAction = (wishlistButton) => {
    const { wishlistButtonAction, wishlistType } = wishlistButton.dataset

    if (['add', 'remove'].includes(wishlistButtonAction)) {
      updateWishlistActions(wishlistButton)
    } else {
      handleRedirect(wishlistButton)
    }
  }

  const handlePriceDecreaseSubscribingClick = () => {
    const subscribeButton = document.querySelector('[data-wishlist-type="price-decrease"]')

    if (!subscribeButton) return

    subscribeButton.addEventListener('click', () => {
      const wishlistButton = document.querySelector('[data-wishlist-button="true"]')
      if (wishlistButton) {
        wishlistButton.setAttribute('data-wishlist-type', 'price-decrease')
        handleWishlistAction(wishlistButton)
      }
    })
  }

  // The click event is attach to the document, to allow interaction with buttons added dynamically
  document.addEventListener('click', (event) => {
    const wishlistButton = event.target.closest('[data-wishlist-button="true"]')

    if (!wishlistButton) return

    event.preventDefault()
    event.stopPropagation()

    handleWishlistAction(wishlistButton)
  })

  handlePriceDecreaseSubscribingClick()
})
