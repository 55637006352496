import React from 'react'
import PropTypes from 'prop-types'
import { toast, Toaster } from 'react-hot-toast'

// TODO jbe - homogenize and upgrade this component according to Campsider UI

export function CustomToaster() {
  return <Toaster containerClassName="react-toast-container" />
}

export const showToast = (message, type = 'danger', duration = 5000) =>
  toast((t) => <Toast message={message} type={type} onClose={() => toast.dismiss(t.id)} />, {
    duration,
    position: 'top-right',
    style: {
      padding: '0px',
      margin: '0px',
      boxShadow: '0 1px 3px rgba(0,0,0,0.06), 0 1px 2px rgba(0,0,0,0.12)',
    },
  })

function Toast({ message, onClose, type }) {
  return (
    <div className={`notification is-${type} is-light`}>
      {message}
      <button type="button" className="delete" onClick={onClose}>
        {' '}
      </button>
    </div>
  )
}

Toast.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
  type: PropTypes.string,
  onClose: PropTypes.func.isRequired,
}

Toast.defaultProps = {
  message: 'Une erreur est survenue.',
  type: 'danger',
}
