window.addEventListener('DOMContentLoaded', () => {
  const promoCodeForms = document.querySelectorAll('.campsider-modal[data-id="promo-code"] form')

  promoCodeForms.forEach((promoCodeForm) => {
    promoCodeForm.addEventListener('submit', (e) => {
      e.preventDefault()

      const data = new FormData(promoCodeForm)
      let results = ''

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, val] of data.entries()) {
        if (val) results += `key : ${key}, value : ${val} \n`
      }

      alert(`TODO promo_code : ${results}`)
    })
  })
})
