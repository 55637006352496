export const ASIDE_MODAL_OPEN_EVENT = 'aside-modal-open'

/**
 * Hide a specific modal
 *
 * @param {string} modalId
 */
export function hideAsideModal(modalId) {
  const asideModal = document.getElementById(modalId)
  if (!asideModal) return

  const background = asideModal.querySelector('.aside-modal__background')

  asideModal.classList.remove('aside-modal--visible')
  background.classList.remove('aside-modal__background--visible')
  document.documentElement.classList.remove('modal--open')
}

/**
 * Open a specific aside-modal
 *
 * @param {string} modalId
 */
export function openAsideModal(modalId) {
  const asideModal = document.getElementById(modalId)
  if (!asideModal) return

  const background = asideModal.querySelector('.aside-modal__background')
  const content = asideModal.querySelector('.aside-modal__content')

  const openEvent = new Event(ASIDE_MODAL_OPEN_EVENT, { bubbles: true })

  asideModal.classList.add('aside-modal--visible')
  background.classList.add('aside-modal__background--visible')
  content.classList.add('aside-modal__content--visible')

  document.documentElement.classList.add('modal--open')

  asideModal.dispatchEvent(openEvent)
}

/**
 * Show the loader for a specific aside-modal
 *
 * @param {string} modalId
 */
export const showAsideModalLoader = (modalId) => {
  const asideModalLoader = document.querySelector(`#${modalId} .aside-modal__loader`)
  const asideModalBody = document.querySelector(`#${modalId} .aside-modal__body`)

  if (!asideModalLoader || !asideModalBody) return

  asideModalLoader.classList.add('aside-modal__loader--loading')
  asideModalBody.classList.add('aside-modal__body--loading')
}

/**
 * Hide the loader for a specific aside-modal
 *
 * @param {string} modalId
 */
export const hideAsideModalLoader = (modalId) => {
  const asideModalLoader = document.querySelector(`#${modalId} .aside-modal__loader`)
  const asideModalBody = document.querySelector(`#${modalId} .aside-modal__body`)

  if (!asideModalLoader || !asideModalBody) return

  asideModalLoader.classList.remove('aside-modal__loader--loading')
  asideModalBody.classList.remove('aside-modal__body--loading')
}

/**
 * Handle click on an element with the attribute : data-aside-modal-id
 * It will open the modal with the id given in data-close-aside-modal-id
 *
 * This is a private method, that should not be uses outside this file
 *
 * @param {HTMLElement} trigger
 */
function handleOpenAsideModalEvent(trigger) {
  const { dataset } = trigger
  if (!dataset) return

  const { asideModalId } = dataset
  if (!asideModalId) return

  openAsideModal(asideModalId)
}

/**
 * Handle click on an element with the attribute : data-aside-modal-id
 * It will close the modal with the id given in data-close-aside-modal-id
 *
 * This is a private method, that should not be uses outside this file
 *
 * @param {HTMLElement} trigger
 */
function handleCloseAsideModalEvent(trigger) {
  const { dataset } = trigger
  if (!dataset) return

  const { closeAsideModalId } = dataset
  if (!closeAsideModalId) return

  hideAsideModal(closeAsideModalId)
}

function handleClick(event) {
  const { target } = event
  const openTrigger = target.closest('[data-aside-modal-id]')
  const closeTrigger = target.closest('[data-close-aside-modal-id]')

  if (openTrigger) handleOpenAsideModalEvent(openTrigger)

  if (closeTrigger) handleCloseAsideModalEvent(closeTrigger)
}
window.addEventListener('DOMContentLoaded', () => {
  document.addEventListener('click', handleClick)
})
